@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-y: hidden;
  font-family: "Baloo 2", sans-serif;
  background-color: #f9f9ff;
}

html,
body {
  scroll-behavior: smooth;
}

#sidebar-wrapper::-webkit-scrollbar {
  width: 3px;
}

#sidebar-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#sidebar-wrapper::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.151);
  border-radius: 10px;
}

#sidebar-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.151);
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.fc-day:hover {
  cursor: pointer;
}

.custom-modal {
  max-width: 800px;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #9999f1;
  color: #252363;
}

.roundedSelect .ant-select-selector {
  border: 1px solid #9999f1;
  color: #252363;
  border-radius: 20px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #ccccf8;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-select-multiple
  .ant-select-selection-placeholder {
  color: #ccccf8;
}

/*no place holder*/
.noplaceholder::placeholder {
  color: #ccccf8;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-upload-wrapper
  .ant-upload-drag {
  color: #252363;
  background-color: #f9f9ff;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: #6666ea;
  border-color: #9494f5;
}

.custom-tabs .ant-tabs-tab {
  width: 100px;
  border-radius: 25px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  border: none;
  margin-right: 8px; /* Optional: Add some spacing between tabs */
}

.custom-tabs .ant-tabs-nav {
  border-bottom: none;
}

.custom-tabs .ant-tabs-tab-active {
  background-color: #6666ea;
  color: white;
}
.ant-tabs-nav::before {
  display: none;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-1k979oh).ant-tabs-top
  > div
  > .ant-tabs-nav
  .ant-tabs-ink-bar {
  display: none;
}
:where(.css-dev-only-do-not-override-1k979oh).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: white;
  text-shadow: 0 0 0.25px currentcolor;
}

/*new style*/

.admin-section,
.manage-section {
  color: #757494 !important;
}

.admin-section-active,
.manage-section-active {
  background-color: #ffffff !important;
  color: #6c63ff !important;
  border-radius: 0px 50px 50px 0px;
}
.css-wx7wi4 {
  color: #a3a3f2;
}

/*Editot styles*/

.ql-toolbar.ql-snow {
  border: 1px solid #ccccf8 !important;
  background-color: #f2f2fe;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/*After clean up*/
/*Cutom Rounded Button*/

.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
