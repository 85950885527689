/*Sign out*/
#signoutDiv {
    margin-right: 1rem;
    margin-top: .4rem;
}

/*Notification*/
#notifyDiv {
    position: relative;
    margin-right: 1.8rem;
    margin-top: .4rem;
}

#notifyNum {
    font-size: 8px;
    background-color: #FCCF3B;
    color: #fff;
    padding: 3px 5px;
    border-radius: 50px;
    position: absolute;
    bottom: .5rem;
    left: .4rem;
}

/*Search Bar*/
#searchBar {
    position: absolute;
    right: 0;
    justify-content: center;
}

#search_form {
    border: 1px solid #E5E5FC;
    width: 344px;
    height: 30px;
    border-radius: 30px 0px 0px 30px;
}

#search-btn {
    background-color: #9999F1;
    border-radius: 0px 30px 30px 0px;
    height: 30px;
    width: 50px;
}

#searchIcon {
    font-size: 14px;
    position: absolute;
    top: 6px;
    right: 0;
    left: 0;
    bottom: 0;
}

#plus-btn {
    color: #fff;
    background-color: #6666EA;
    margin-left: 2rem;
    font-size: 14px;
    padding: 4px 10px;
}


#profile_drop {
    margin-right: .8rem;
    cursor: pointer;
}


#buttons_holder {
    position: absolute;
    right: 0;
}




/*===============Pro SideBar ================*/
#active {
    background-color: #F2F2FE;
    color: #6666EA;
    border-radius: 0px 50px 50px 0px;
    max-width: 240px;
}