#nprogress .bar {
  background: #6666ea !important;
  height: 2px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #6666ea, 0 0 5px #6666ea !important;
}

#nprogress .spinner-icon {
  border-top-color: #6666ea !important;
  border-left-color: #6666ea !important;
}
